<template>
    <!-- <div>
        <h2>Items</h2>
        <ul>
            <li v-for="item in items" :key="item.id">{{ item.name }}</li>
        </ul>
    </div> -->
    <form @submit.prevent="sendEmailFn" v-if="!formSubmitted">
        <div>
            <input
                v-model="email"
                type="email"
                placeholder="Enter your email"
                style="width: 200px"
                required
            /><br />
            <input
                v-model="emailMessage"
                type="text"
                placeholder="Enter your message"
                required
            /><br />
            <input class="submit" type="submit" value="Send email" />
        </div>
    </form>
</template>

<script>
import api from "../../api/axios";

export default {
    data() {
        return {
            items: [],
            email: "",
            emailMessage: "",
        };
    },
    mounted() {
        this.fetchItems();
    },
    methods: {
        async fetchItems() {
            try {
                const response = await api.get("/users");
                this.items = response.data;
                console.log("data >> ");
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching items:", error);
            }
        },
        async sendEmailFn() {
            const data = {
                email: this.email,
                message: this.emailMessage,
            };

            try {
                const response = await api.post("/send-email", data);
                console.log("email response >> ");
                console.log(response.data);
            } catch (error) {
                console.error("Error sending email:", error);
            }
        },
    },
};
</script>

<style scoped>
.start:hover {
    background-color: hsl(355, 100%, 74%);
    color: white;
}

.cta {
    width: 25%;
    display: flex;
    justify-content: space-evenly;
}

.cta a {
    border-radius: 2rem;
    font-weight: bold;
    cursor: pointer;
}

.start {
    border: none;
    background-color: white;
    color: hsl(356, 100%, 66%);
    padding: 3.25% 8.75%;
}

/* Styles specific to this component */
</style>
