import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import TrainerSignUp from './components/TrainerSignUp.vue';
import WelcomePage from './components/WelcomePage.vue';

  const routes = [
    { path: '/', component: WelcomePage },
    { path: '/register', component: TrainerSignUp }
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes: routes
  });
 
const app = createApp(App);
app.use(router);
app.mount('#app');