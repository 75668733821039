<template>
    <div class="desingForFuture-container">
        <h1>Designed for the future</h1>
        <div class="designForFuture-sectionUp">
            <div class="sectionUp-text">
                <h4>Introducing an extensible editor</h4>
                <p>
                    Elevate your chess game to the next level with personalized
                    one-on-one coaching from our pool of experienced chess
                    masters. Whether you're a beginner eager to learn the basics
                    or a seasoned player seeking to refine your strategy, our
                    platform provides the perfect match for your learning needs.
                    Enjoy interactive sessions tailored to your skill level,
                    receive personalized feedback, and unlock your full chess
                    potential in a supportive and engaging environment.
                </p>
                <h4>Robust content management</h4>
                <p>
                    Are you passionate about chess and eager to share your
                    knowledge with the next generation of players? Our platform
                    offers you the opportunity to build a successful coaching
                    career, connecting with students seeking your expertise. Set
                    your own schedule, create personalized lesson plans, and
                    make a real impact on the lives of aspiring chess players.
                    Join a vibrant community of fellow coaches, share your
                    passion, and build a rewarding career centered around your
                    love for the game.
                </p>
            </div>
            <div class="sectionUp-img">
                <img
                    src="../images/illustration-editor-desktop.svg"
                    alt="illustration-editor"
                />
            </div>
        </div>
        <StateOfArt />
        <div class="designForFuture-sectionDown">
            <div class="sectionDown-img">
                <img
                    src="../images/illustration-laptop-desktop.svg"
                    alt="illustration-laptop"
                />
            </div>
            <div class="sectionDown-text">
                <h4>Free, open, simple</h4>
                <p>
                  <ul>
                    <li>Personalized coaching: Receive expert guidance tailored to
                    your skill level and learning style. </li> <li>Experienced chess
                    masters: Learn from the best with our pool of qualified and
                    passionate coaches.</li>
                    <li>Interactive sessions: Engage in dynamic
                    lessons with puzzles, tactics training, and live board
                    analysis.</li>
                    <li>Flexible scheduling: Choose the time and pace that
                    best suits your learning needs.</li>
                  </ul>
                </p>
                <h4>Powerful tooling</h4>
                <p>
                  <ul>
                  <li>Connect with ideal students: Find players seeking your expertise and build meaningful connections.</li>
                  <li>Set your own schedule and rates: Control your teaching experience and earn competitive compensation.</li>
                  <li>Build a successful career: Grow your student base and establish yourself as a renowned chess coach.</li>
                  <li>Join a supportive community: Connect with fellow coaches, share best practices, and learn from each other.</li>
                </ul>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import StateOfArt from "./StateOfArt.vue";

export default {
    name: "DesignForFuture",
    components: {
        StateOfArt,
    },
};
</script>

<style scoped>
.desingForFuture-container {
    padding-top: 10%;
    padding-bottom: 9%;
    position: relative;
    overflow-x: hidden;
}

.desingForFuture-container h1,
.desingForFuture-container h4 {
    color: hsl(208, 49%, 24%);
    letter-spacing: 0.025rem;
}

.desingForFuture-container p {
    color: hsl(207, 13%, 34%);
    letter-spacing: 0.025rem;
}

.desingForFuture-container h1 {
    text-align: center;
    padding-bottom: 6.5%;
    font-weight: bold;
    font-size: 2.5vw;
}

.designForFuture-sectionUp {
    display: flex;
    padding-left: 10.5%;
    padding-bottom: 11%;
}

.designForFuture-sectionDown {
    display: flex;
    justify-content: flex-end;
    padding-right: 10.5%;
    padding-top: 22.5%;
}

.sectionUp-text,
.sectionDown-text {
    width: 44.5%;
}

.sectionUp-text h4,
.sectionDown-text h4 {
    font-size: 2vw;
    padding-bottom: 5.25%;
}

.sectionUp-text p,
.sectionDown-text p {
    line-height: 1.75rem;
    padding-bottom: 14%;
}

.sectionUp-img {
    position: absolute;
    top: 2.5%;
    right: -19%;
}

.sectionDown-img {
    position: absolute;
    bottom: 5%;
    left: -18.5%;
}

.sectionUp-img img,
.sectionDown-img img {
    width: 64vw;
}

@media (max-width: 800px) {
    .desingForFuture-container h1 {
        font-size: 3vw;
    }

    .designForFuture-sectionUp {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-left: 0;
        padding-bottom: 22%;
    }

    .designForFuture-sectionDown {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        padding-right: 0;
        padding-top: 11%;
    }

    .sectionUp-text h4,
    .sectionDown-text h4 {
        font-size: 2.5vw;
        padding-bottom: 3%;
    }

    .sectionUp-img {
        position: relative;
        top: 0;
        right: 0;
        padding-bottom: 7%;
    }

    .sectionDown-img {
        position: relative;
        bottom: 0;
        left: 0;
        padding-bottom: 7%;
    }

    .sectionUp-text,
    .sectionDown-text {
        width: 80%;
        text-align: center;
    }

    .sectionUp-text p,
    .sectionDown-text p {
        padding-bottom: 7%;
    }
}

@media (max-width: 480px) {
    .desingForFuture-container h1 {
        font-size: 5vw;
    }

    .sectionUp-img img,
    .sectionDown-img img {
        width: 90vw;
    }

    .designForFuture-sectionUp {
        padding-bottom: 35%;
    }

    .sectionUp-text h4,
    .sectionDown-text h4 {
        font-size: 4vw;
    }
}
</style>
